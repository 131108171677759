<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <h2 class="mb-4">Bromination-Elimination Reaction of an Enone</h2>
      <p class="pl-8 mb-5">
        <v-img max-width="450" src="/img/assignments/bromination-elimination.png" />
      </p>
      <p class="mb-3">
        A solution containing
        <number-value :value="massEnone" unit="\text{g}" />
        of enone in
        <stemble-latex content="$\text{58 mL}$" />
        of dichloromethane was cooled to
        <stemble-latex content="$\text{0}^\circ\text{C.}$" />
        A solution containing
        <number-value :value="volBromine" unit="\mu\text{L}" />
        of bromine in
        <stemble-latex content="$\text{13 mL}$" />
        of dichloromethane was then added over a period of 1.5 hours using an addition funnel. After
        stirring for an additional 60 minutes at
        <stemble-latex content="$\text{0}^\circ\text{C,}$" />
        a solution containing
        <stemble-latex content="$\text{1.6 mL}$" />
        of triethylamine in
        <stemble-latex content="$\text{12 mL}$" />
        of dichloromethane was added over a 30 minute period. The reaction was stirred for another
        30 minutes at
        <stemble-latex content="$\text{0}^\circ\text{C}$" />
        before being filtered through a 10 g pad of celite.
      </p>

      <p class="mb-2">
        The filtrate (liquid) was then washed with
        <stemble-latex content="$\text{40 mL}$" />
        of
        <stemble-latex content="$\text{1 M HCl(aq)}$" />
        followed by
        <stemble-latex content="$\text{40 mL}$" />
        of saturated
        <stemble-latex content="$\ce{NaHCO3(aq)}\text{.}$" />
        The combined aqueous layers were extracted with
        <stemble-latex content="$\text{2-40 mL}$" />
        portions of dichloromethane. The combined organic layers were extracted with
        <stemble-latex content="$\text{40 mL}$" />
        of saturated
        <stemble-latex content="$\text{NaCl}$" />
        and then dried over
        <stemble-latex content="$\text{3 g of }\ce{MgSO4}\text{.}$" />
        The organic solvent was removed by rotary evaporation and the product was purified by column
        chromatography yielding
        <number-value :value="massProduct" unit="\text{g}" />
        of a white solid.
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import NumberValue from '../NumberValue';

export default {
  name: 'PercentYieldLab2',
  components: {
    StembleLatex,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  computed: {
    volBromine() {
      return this.taskState.getValueBySymbol('volBromine').content;
    },
    massEnone() {
      return this.taskState.getValueBySymbol('massEnone').content;
    },
    massProduct() {
      return this.taskState.getValueBySymbol('massProduct').content;
    },
  },
};
</script>
